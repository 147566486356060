import { captureMessage as captureSentryMessage } from '@sentry/browser'
export const captureException = (exception) => {
  if (process.env.NODE_ENV !== 'development') {
    throw new Error(exception)
  }
}

export const captureMessage = (message = 'empty') => {
  if (process.env.NODE_ENV !== 'development') {
    try {
      captureSentryMessage(JSON.stringify(message))
    } catch (ex) {
      console.log(ex)
    }
  }
}
