export const messages = Object.freeze({
  profileSuccess: 'Your profile was successfully updated!',
  contactSuccess: 'Thank you! Your message has been sent.',
  resetSuccess: 'Email has been sent to you! Please, check your inbox.',
  favoriteStoresSaved: 'Your favorite stores were saved!',
  errors: {
    incorrectEmail: 'Incorrect email.',
    alreadySubscribed: 'This email is already registered. <a class="underline text-blue-650" href="/login">Want to Sign In?</a>',
    serverError: 'Oops! Something went wrong. Please, refresh the page  and try again.',
    wrong: 'Oops! Something went wrong. Please, try again.',
    404: 'Oops! Looks like there’s nothing here.',
    paypalDown: 'Oops! Something went wrong. Please, refresh this page and try again.',
    requestFailed: 'Request failed.',
    token: 'Oops! Something went wrong. Please, refresh this page and try again'
  }
})

export const EMAIL_STATUS = Object.freeze({
  SUBSCRIBER: 'SUBSCRIBER',
  INVALID: 'INVALID',
  VALID: 'VALID'
})

export const API_STATUS = Object.freeze({
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS'
})

const API = {
  BUDGET_DASHBOARD: '/api/budgets/dashboard',
  BUDGET_UPDATE: '/api/budgets',
  EXPENSES: '/api/expenses',
  COUPONS: '/api/coupons',
  COUPONS_FAVORITE: '/api/coupons/favorite',
  COUPONS_FEATURED: '/api/coupons/featured',
  SEARCH_STORES_COUPONS: '/api/stores-and-coupons',
  POPULAR_COUPONS: '/api/coupons/popular',
  POPULAR_STORES: '/api/stores/popular',
  POPULAR_BOOKS: '/api/books/popular',
  STORES: '/api/stores/',
  BOOKS: '/api/books',
  PDF: '/api/pdf',
  USERS: '/api/users',
  STORE_SUGGESTION: '/api/suggestions',
  SET_ZODIAC: '/api/users/horoscope',
  KETO_MEAL: '/api/users/keto/',
  BULK_FAVORITE_STORES: '/api/users/stores/favorites',
  STORE_FAVORITE: (storeId = 'empty') => (`/api/users/stores/${storeId}/favorite`),
  SAVED_STORES: '/api/stores/saved',
  FAVORITE_COUPONS: '/api/coupons/favorite',
  USER_ACTIONS: '/api/users/action'
}

const CATEGORIES = {
  1: {
    title: 'Groceries',
    color: '#3669C9',
    total: 0,
    icon: require('../img/icons/dashboard/groceries.svg')
  },
  2: {
    title: 'Entertainment',
    color: '#981497',
    total: 0,
    icon: require('../img/icons/dashboard/entertainment.svg')
  },
  3: {
    title: 'Restaurants',
    color: '#1AB046',
    total: 0,
    icon: require('../img/icons/dashboard/restaurants.svg')
  },
  4: {
    title: 'Transport',
    color: '#DA3B21',
    total: 0,
    icon: require('../img/icons/dashboard/transport.svg')
  },
  5: {
    title: 'Shopping',
    color: '#FD9927',
    total: 0,
    icon: require('../img/icons/dashboard/shopping.svg')
  },
  6: {
    title: 'Travel',
    color: '#11AEF1',
    total: 0,
    icon: require('../img/icons/dashboard/travel.svg')
  },
  7: {
    title: 'Utilities',
    color: '#375692',
    total: 0,
    icon: require('../img/icons/dashboard/utilities.svg')
  },
  8: {
    title: 'Health',
    color: '#1DBAB0',
    total: 0,
    icon: require('../img/icons/dashboard/health.svg')
  },
  9: {
    title: 'General',
    color: '#6A8CAD',
    total: 0,
    icon: require('../img/icons/dashboard/general.svg')
  }
}

const COUPON_TYPES = {
  1: 'sale',
  2: 'coupon',
  SIMPLE: 1,
  FEATURED: 2,
  EXCLUSIVE: 3
}

const ORDER_BY_TYPES = {
  name: 'A-Z',
  popular: 'Most Popular',
  recent: 'Most Recent',
  favorite: 'My Favorites'
}

const ORDER_BY_TYPES_DEALS = {
  recent: 'Most Recent',
  popular: 'Most Popular',
  exclusive: 'Exclusive'
}

export {
  API,
  CATEGORIES,
  COUPON_TYPES,
  ORDER_BY_TYPES,
  ORDER_BY_TYPES_DEALS
}

export const measures = Object.freeze({
  imperial: 2,
  metric: 1
})

export const valueRanges = Object.freeze({
  imperial: {
    height: {
      min: {
        feet: 2,
        inches: 7
      },
      max: {
        feet: 8,
        inches: 2,
        maxInches: 12
      }
    },
    weight: {
      min: 66,
      max: 661
    }
  },
  metric: {
    height: {
      min: 80,
      max: 250
    },
    weight: {
      min: 30,
      max: 300
    }
  }
})

export const discounts = [
  {
    id: '10OFF',
    price: {
      default: 10,
      INR: 700
    }
  }
]

export const USER_ACTIONS = {
  BEST_DISCOUNTS_VIEW: 'best_discounts_view',
  BUDGETING_TOOL_VIEW: 'budgeting_tool_view',
  QUIZ_FILLED: 'quiz_filled',
  HOROSCOPE_SAVED: 'horoscope_saved',
  DASH_STORES_SAVED: 'dash_stores_saved'
}

export const COUPON_LOCATION = {
  1: 'Store',
  2: 'Online',
  3: 'Store & Online'
}
